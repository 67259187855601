import { useLayoutEffect, useRef } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import utils from 'utils';
import cmsUtils from 'cms/utils/cmsUtils';
import Hammer from 'hammerjs';

export function ImageBeforeAfter(props){
    
    const item = props.item;
    const index = props.index;
    //console.log("item", item);
    const cmsOption = props.cmsOption || {};
    const imagesUrl = (cmsUtils.payload(item, 'ImageUrl') || "https://dummyimage.com/2560x800/333333/fff").split("|", 2);
    //const imagesUrl = ("https://dummyimage.com/2560x800/333333/fff").split("|", 2);
    //const imagesUrl = ("https://embarkarb.stg.jaba.com.au/sites/embarkarb/media/banners/treepruning2-1.jpg").split("|", 2);
    
    const imageBeforeAfterItem = useRef(null);

    useLayoutEffect(() => {

        const subject = imageBeforeAfterItem.current.querySelector('.before-and-after_' + index);
        const scraper = imageBeforeAfterItem.current.querySelector('.subject-scraper_' + index);
        const after = imageBeforeAfterItem.current.querySelector('.subject-after_' + index);

        const hammer = new Hammer.Manager(subject);
        hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 }));
        var px = 0;

        if(!cmsOption.isCmsEdit){
            hammer.on('panstart pan panend', e => {
                dragScraper(e);
            });
        }

        function dragScraper(event){
            px = event.center.x - ((window.innerWidth - subject.clientWidth) / 2) + 5;

            if(px <= 20) {
                px = 20;
            }
            else if(px >= (subject.clientWidth) - 20){
                px = (subject.clientWidth) - 20;
            }   

            scraper.style.transform = 'translate(' + px + 'px, 0)';
            after.style.transform = 'translate(-' + px + 'px, 0)';
            
            var abc = document.querySelectorAll(
                "div.image-be-af-panel" +
                " .slick-slide.slick-cloned" +
                " .before-and-after_" + index +
                " .subject-scraper_" + index
            );
            
            abc.forEach((userItem) => {
                userItem.style.transform = 'translate(' + px + 'px, 0)';
                userItem.querySelector("img.subject-after_" + index).style.transform = 'translate(-' + px + 'px, 0)';
            });
        }

        function prventI(e) {
            e.preventDefault();
        }

        imageBeforeAfterItem.current.addEventListener("contextmenu", prventI);
        imageBeforeAfterItem.current.addEventListener("selectstart", prventI);
        imageBeforeAfterItem.current.addEventListener("dragstart", prventI);

    }, [cmsOption.isCmsEdit, index]);
    const cssClass = utils.classNames('cms_item', 'image-before-and-after');
    return (
        <div className = {cssClass} ref={imageBeforeAfterItem} >
            <div css = {style.beforeAndAfter} className = {'before-and-after_' + index}>
                <img css = {style.subjectBefore} src={imagesUrl[0]} alt = "t1"/>
                <div css = {style.subjectScraper} className = {'subject-scraper_' + index}>
                    <img css = {style.subjectAfter} className =  {'subject-after_' + index} src={ imagesUrl[1] || imagesUrl[0]} alt = "t2"/>
                </div>
            </div>
        </div>
        
    );
}