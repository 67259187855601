/** @jsx jsx */
import {jsx} from '@emotion/core'
import utils from 'utils';
import { ImageBeforeAfter } from '../item/view';
//import cmsUtils from 'cms/utils/cmsUtils';
import Slider from "react-slick";
import style from './style';


export function ImageBeAfPanel(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'image-be-af-panel', item.cssClass || item.anchorName || '');
    const subItems = item.items || [];
    
    const imageBeAfItems = subItems.map((subItem, index) => {
            return <ImageBeforeAfter item={subItem} index = {index} key={subItem.itemId} cmsOption ={cmsOption} ></ImageBeforeAfter>    
        }
    );

    var settings = {
        dots: false,
        //infinite: true,
        //speed: 1500,
        //autoplaySpeed: 3000,
        //autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        swipe : false
    };

    return (
        <div css = {style.imageBeAfPanel} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <Slider {...settings}>
                { imageBeAfItems }
            </Slider>
        </div>
        // <div className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        //     { imageBeAfItems }
        // </div>
    )
}