const config = {
  isProd: process.env.NODE_ENV === "production",
  apiBase: process.env.REACT_APP_API_ENDPOINT,
  resourceBase: process.env.REACT_APP_RESOURCE_BASEURL,
  cmsEndpoint: process.env.REACT_APP_CMS_ENDPOINT,
  ver: process.env.REACT_APP_VERSION,
  updateDate: process.env.REACT_APP_UPDATED_AT,
  gaAccounts: getJson(process.env.REACT_APP_GA_ACCOUNTS)
}

function getJson(val) {
  return val ? JSON.parse(val) : val;
}

console.log(process.env, config)
export default {
  ...config
}