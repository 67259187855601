//import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core'

export const ErrorPopup = (props) => {
  const title = props.title || 'Errors';
  const errors = props.errors;
  let messages = [];
  if (errors) {
    if (Array.isArray(errors)) {
      messages = errors;
    } else {
      messages.push(errors);
    }
  }

  const style = css({
    position: 'fixed',
    padding: '3rem',
    width: '800px',
    maxWidth: '90vw',
    maxHeight: '90vw',
    background: 'white',
    color: 'red',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  })

  return <div css={style}>
    <h3>{title}</h3>
    {props.children}
    {messages.map((error, inx) => (
      <div key={inx} dangerouslySetInnerHTML={{ __html: error }}></div>
    ))}
    
  </div>
}
