export function isExternalLink(link){
  return link && (link.indexOf('https://') === 0 || link.indexOf('http://') === 0 || link.indexOf('://') === 0);
}

export function getLinkFromElement(el){
  if (!el) return null;
  const linkEl = el.closest('a');
  //console.log(el, linkEl);
  if (linkEl){
    return linkEl.getAttribute('href');
  }
  return null;
}


// router utils
export function getLinkInfo(link) {
  if (!link) return null;

  const origin = typeof window !== `undefined` && window.location && window.location.origin;
  const currentPath = typeof window !== `undefined` && window.location && window.location.pathname;
  let linkFiltered = link.replace(origin, '').replace(currentPath + '#', '#');
  let linkLower = link.toLowerCase();

  //if (isStaticFile(linkLower)) return null;
  const isExternal = isExternalLink(linkFiltered);
  const isHash = linkFiltered.indexOf('#') === 0;
  const isTel = linkFiltered.indexOf('tel:') === 0;
  const isMailTo = linkFiltered.indexOf('mailto:') === 0;
  const type = (isHash && 'hash') || (isTel && 'tel') || (isMailTo && 'mailto') || 
    (isStaticFile(linkLower) && 'static') || (isExternal && 'external') || 'internal';

  return { url: linkFiltered, type: type, ext: getExtension(link) };
}

var exts = ['.pdf', '.jpg', '.png', '.gif', '.docx']
function isStaticFile(path) {
  for(let i = 0; i < exts.length; i++) {
    if (path.indexOf(exts[i]) >= 0) return true;
  }
  return false;
}

export function getExtension(filename) {
  if (!filename || filename.indexOf('.') < 0) return null;
  return filename.split('.').pop().toLowerCase();
}

export function getFilename(url) {
  return url.split('/').pop().split('?')[0];
}

//
export function toQueryString(params) {
  var queryString = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&');
  return queryString;
}

export function getYoutubeId(url){
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}