import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default{
    testimonialItem : css({
        maxWidth : '300px',
        backgroundColor: '#f8f8ff',
        opacity: '.9',
        textAlign: 'center',
        color: '#000',
        padding: '10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 'auto'
    }),
    testimonialItem_title : css({
        marginBottom: '0px',
        fontSize: '1.2rem'
    }),
    testimonialItem_desc : css({
        flex: '1 1 auto',
        fontSize: '1rem'
        //flexGrow: '1',
        //flexShrink: '1'
    }),
    testimonialItem_bottom :css({
        marginTop: '1rem'
    })
}