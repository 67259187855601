//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default {
    multiColumnPanelMain : css({
        //maxWidth : '960px',
        //margin : 'auto',
        '& .row' : {
            maxWidth: '960px !important',
            margin: 'auto !important'
        },
        '& .htmlPanel' : {
            maxWidth: 'unset !important',
            margin: 'unset !important'
        }
    }),
    colStyle : css({
        paddingLeft : '0px !important',
        paddingRight : '0px !important'
    })
}