//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    pageTiles : css({
         display: 'flex',
         justifyContent: 'center',
         flexWrap: 'wrap',
         margin: '2rem -10px',
         //alignItems: 'stretch'
    }),

    pageTile__tile : css(mq({
        flexBasis: ['100%', '100%', '45%'],
        maxWidth : ['100%', '100%', '45%'],
        margin: '10px 10px',
        position: 'relative',
        overflow : 'hidden'
        //border: '1px solid red'
    })),

    tile__container : css({
        position : 'absolute',
        height : '100%',
        width : '100%',
        color : '#f5f5f5',
        padding: '10px 20px 20px',
        transition: 'all .5s',
        backgroundColor : 'rgba(35,35,35, .7)',
        top : '84%'
    }),

    tile__bg : css(mq({
        paddingTop: '18rem',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition: '.5s'
        //flex: '1 1 300px',
    })),

    tile__content : css({
        '&:hover' : {
            '& .pageTile__fishing__container' : {
                top : '0px'
            },
            '& .pageTile__fishing__desc' : {
                display: 'block'
            },
            '& .pageTile__fishing__title' :{
                marginTop: '2rem'
            }
        }
    }),

    tile__title : css(mq({
        margin: '0px',
        //fontSize: ['30px', '20px', '2rem']
        fontSize: '2rem'
    })),

    tile__desc : css(mq({
        display: 'none',
        marginTop: ['1rem', '1.5rem']
    }))
}