import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    imageBeAfPanel : css(mq({
        '& .slick-slider .slick-prev' : {
            left : ['20px', '-36px', '-26px', '-20px']
        },
        '& .slick-slider .slick-next' : {
            right : ['20px', '-36px', '-26px', '-20px']
        }
    }))
}