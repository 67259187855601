import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default {
    beforeAndAfter : css({
        position : 'relative',
        overflow: 'hidden',
        cursor: 'ew-resize',
        //width: 75%;,
        maxWidth : '700px',
        margin: 'auto',
        '& img' : {
            display: 'block',
            maxWidth: '100%'
        }
    }),
    subjectBefore : css({
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '-2',
        width: '100%'
    }),

    subjectScraper : css({
        overflow: 'hidden',
        transform: 'translate(50%, 0)',
        '&:before' : {
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '20',
            height: '100%',
            width: '.25em',
            content: '""',
            background: '#fff'
        }
    }),
    subjectAfter : css({
        transform: 'translate(-50%, 0)'
        //filter: 'grayscale(100%)'
    })
}