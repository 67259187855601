import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import  utils from 'utils';
import { SiteLink } from 'components';

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  //const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', item.cssClass || item.anchorName || '');
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');

  //console.log('item', bgUrl);
  const titlePanel = captionTitle ? (
    linkUrl ? (<SiteLink to={linkUrl} className="banner-item__link">
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
    </SiteLink>) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', ( titlePanel ? 'mask' : ''), item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
      { titlePanel && 
        
          <div className="banner_item__text">
            <div className="container">
              <div className="banner_item__text__warpper">
                {titlePanel}
              </div>
            </div>
          </div>
      }
    </div>
  );
}