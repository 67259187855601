import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';


export function Code(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, cmsOption);

  const code = cmsUtils.payload(item, 'Code');
  const codeType = cmsUtils.payload(item, 'CodeType');
  let controls = null;
  if (codeType === 'Json') {
    //const codeJson = JSON.parse(code);
    //const searchType = (codeJson.category && 'category') || (codeJson.style && 'style') || '';
    // if (codeJson.control === 'product-list') {
    //   controls = <ProductList category={codeJson.category} style={codeJson.style} searchType={searchType} />
    // } else if (codeJson.control === 'where-to-buy-list') {
    //   controls = <WhereToBuyList />
    // } else if (codeJson.control === 'blog-list') {
    //   controls = <BlogList />
    // }
  }

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  if (cmsOption.isCmsEdit)
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
<pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
<b>Code Type: {codeType}</b>
{code}}</pre>          
        </div>
    )
  else if (codeType === 'Text')
    return (
      <div className={cssClass} id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: code }}></div>
    )
  else
    return (
      <div className={cssClass} id={item.anchorName}>{controls}</div>
    )
}
