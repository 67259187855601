/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { SiteLink } from 'components';

function getPages(pagesSerialized) {
    if (pagesSerialized) {
      return JSON.parse(pagesSerialized);
    }
    return [];
}

function Tile(props) {
    const page = props.page;
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    const disableLink = props.disableLink;
    return (
      
      <div css = {style.pageTile__tile} className="pageTile__fishing">
        <SiteLink className="pageTile__fishing__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}>
            <div className="pageTile__fishing__bg" css = {style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
            <div css = {style.tile__container} className="pageTile__fishing__container">
                <h2 css={style.tile__title} className="pageTile__fishing__title">
                    {page.pageTitle}
                </h2>
                <div css={style.tile__desc} className="pageTile__fishing__desc">
                    {page.tileDesc || page.description}
                </div>
            </div>
        </SiteLink>
      </div>

    )
  }

export function PageTilesFishing(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
    const pageTileElts = pages.map((page, index) =>
        <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
    );

    const cssClass = utils.classNames('cms_item', 'pageTiles__fishing', item.cssClass || item.anchorName || '');

    return (
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css = {style.pageTiles}>
          {pageTileElts}
        </div>
    );
}