import React from 'react';
import { SiteLink } from 'components';
//import env from 'env';

export function Footer(props) {
  const year = (new Date()).getFullYear();
  //const ver = env.ver;

  return (
    <div className="bottomPanel" id="bottom-panel">
      <SocialIcons />
      <div className="footerLinks">
        <ul>
          <li> Copyright &copy;{year} Embark </li>
          {/* <li><SiteLink to="/terms-and-conditions">Terms &amp; Conditions</SiteLink></li> */}
          <li><SiteLink to="/privacy"> Privacy</SiteLink></li>
          <li><SiteLink to="/disclaimer">Disclaimer</SiteLink></li> 
          <li><a href="https://jaba.com.au" target="_blank" rel="noopener noreferrer"> PWA by JABA </a></li> 
        </ul>   
      </div>
      {/* <div className="go-top">
        <SiteLink to="#top">
          <img src={`${env.resourceBase}/assets/go-to-top.png`} alt="Go to top" />
        </SiteLink>
      </div> */}
    </div>
  );
}

function SocialIcons() {
  return (
    <div className="socials">
      <a className="socials__instagram" href="https://www.instagram.com/embarktrees" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram">
        <span className="sr-only sr-only-focusable">Instagram</span>
      </a>
      <a className="socials__facebook" href="https://www.facebook.com/embarkarboriculture" target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook">
        <span className="sr-only sr-only-focusable">Facebook</span>
      </a> 
      {/* <a className="socials__facebook" href="https://www.facebook.com/embarkarboriculture" target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook">
        <span className="sr-only sr-only-focusable">Facebook</span>
      </a> */}
      {/* <a className="socials__instagram" href="" target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram">
        <span className="sr-only sr-only-focusable">Instagram</span>
      </a>
      <a className="socials__youtube" href="" target="_blank" rel="noopener noreferrer" title="Youtube" aria-label="Youtube">
        <span className="sr-only sr-only-focusable">Youtube</span>
      </a>
      <a className="socials__pinterest" href="" target="_blank" rel="noopener noreferrer" title="Pinterest" aria-label="Pinterest">
        <span className="sr-only sr-only-focusable">Pinterest</span>
      </a> */}
    </div>
  )
}