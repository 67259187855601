import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';

export function BlogPostTitle(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const title = cmsUtils.payload(item, 'PageTitle');

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <h1>{title}</h1>
    </div>
  );
}
