import React from 'react';
import { useRouter } from 'components';
import { SiteLink } from 'components';

export function BottomDock(props) {
  const links = [
    { url: '/about-us', title: 'About Us' },
    { url: '/tree-services', title: 'Tree Services' },
    { url: '/training', title: 'Training' },
    { url: '/our-works', title: 'Our Work' },
    { url: '/faq', title: "FAQ's" }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon"></div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   console.log('useLayoutEffect');
  //   const slider = new BottomSlider(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      {/* ref={bottomMenu} */}
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bar"></div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
      
    </>
  );
}
