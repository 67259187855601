import React from 'react';
import { BannerPanel } from 'cms/items/bannerPanel/view';
import { BannerItem } from 'cms/items/bannerItem/view';
import { Panel } from 'cms/items/panel/view';
import { Html } from 'cms/items/html/view';
//import { TestimonialPanel } from 'cms/items/testimonialPanel/view';
//import { TestimonialItem } from 'cms/items/testimonialItem/view';
import { ImagePanel } from 'cms/items/imagePanel/view';
// import { PageTiles } from 'cms/items/pageTiles/view';
//import { PageTilesBsyc } from 'components/pageTile--bsyc/view';
import { PageTilesFishing } from 'components/pagTileFishing/view';

import { SingleBanner } from 'cms/items/singleBanner/view';
import { ContactForm } from 'cms/items/contactForm/view';
import { YoutubeBannerPanel } from 'cms/items/youtubeBannerPanel/view';
import { Code } from 'cms/items/code/view';
import { BlogPosts } from 'cms/items/blogPosts/view';
import { BlogPostTitle } from 'cms/items/blogPostTitle/view';

//import { ImageBeforeAfter } from 'components/imageBeforeAfter/view';
import { ImageBeAfPanel, ImageBeforeAfter } from 'components/imageBeforeAfter/index';
import { MultiColumnPanel } from 'components/multiColumnPanel/view';
import { PhotoGallery } from 'components/photoGallery/view';
import { TestimonialPanel } from 'components/testimonial/index';

export function Item(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems, cmsOption);

  let itemElt = (<></>);
  if (item.itemTypeCode === 'Panel') {
    itemElt = (<Panel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Html') {
    itemElt = (<Html item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'BannerPanel') {
    itemElt = (<BannerPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'BannerItem') {
    itemElt = (<BannerItem item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'TestimonialPanel') {
    const setting = {
      startRatingColor : '#77cf22'
    };
    itemElt = (<TestimonialPanel item={item} cmsOption={cmsOption} {...setting} />);
  } else if (item.itemTypeCode === 'ImagePanel') {
    itemElt = (<ImagePanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PageTiles') {
    itemElt = (<PageTilesFishing item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'SingleBanner') {
    itemElt = (<SingleBanner item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ContactForm') {
    itemElt = (<ContactForm item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'YoutubeBannerPanel') {
    itemElt = (<YoutubeBannerPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Code') {
    itemElt = (<Code item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Blog-Posts') {
    itemElt = (<BlogPosts item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'Blog-PostTitle') {
    itemElt = (<BlogPostTitle item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ImageBeforeAfter') {
    itemElt = (<ImageBeforeAfter item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'ImageBeAfPanel') {
    itemElt = (<ImageBeAfPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'MultiColumnPanel') {
    itemElt = (<MultiColumnPanel item={item} cmsOption={cmsOption} />);
  } else if (item.itemTypeCode === 'PhotoGallery') {
    itemElt = (<PhotoGallery item={item} cmsOption={cmsOption} />);
  }  else {
    itemElt = (<div>{item.itemTypeCode}</div>);
  }

  // } else if (item.itemTypeCode === 'TestimonialItem') {
  // itemElt = (<TestimonialItem item={item} cmsOption={cmsOption} />);

  // const subItemsElts = subItems.map((subItem, index) =>
  //   <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  // );
  return (
    <>
      {itemElt}
      {/* {subItemsElts} */}
    </>
  );
}