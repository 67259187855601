import React, { useState, useEffect } from 'react';
import { useRouter } from 'components';
//import { navigate, usePath, useLocationChange } from 'raviger';
import { SiteLink } from 'components';
import { NavBar } from './navBar';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory } from 'components';
import utils from 'utils';
//import { Tracking } from 'lib/seo';

export function TopHeader(props) {

  const { path } = useRouter();
  //const path = usePath();
  const [menuOpened, setMenuOpened] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(path);
  const [scrolled, setScrolled] = useState(false);

  //const [scrolled, setScrolled] = useState(false)
  // const history = useHistory();
  // console.log(history.length, history)

  const history = useHistory(path);

  const routeChanged = (path, history) => {
    //console.log(path, history);
    //console.log(window.location.hash)
    const hash = window.location.hash;
    history.push(path);
    if (hash) { scrollTo(); }
  }

  useEffect(() => {
    scrollTo();
    if(window.scrollY >= 100){
      setScrolled(true);
    }
    window.addEventListener('scroll', function(){
      if(window.scrollY >= 100 ){
        setScrolled(true);
      }
      else{
        setScrolled(false);
      }
    });
  }, []);

  //const pageChanged = useCallback(path => routeChanged(path, history), [history])
  // useLocationChange(path => {
  //   routeChanged(path, history);
  //   setCurrentLocation(path);
  // })

  useEffect(() => {
    routeChanged(path, history);
    setCurrentLocation(path);
    // eslint-disable-next-line
  }, [path]);

  function scrollTo() {
    const hash = window.location.hash;
    if (hash) {
      //console.log(hash)
      setTimeout(() => {
        const anchor = utils.ui.findElement(hash);
        if (anchor)
          utils.ui.scrollTo(anchor, 500);
      }, 500)
    }
  }

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  // function goBack(e) {
  //   //e.preventDefault();
  //   const previousLink = history.getPrevious();
  //   //console.log(previousLink)
  // }

  // useEffect(() => {
  //   console.log('use history')
  // }, []);

  return (
    <>
      <header className={`topNav ${scrolled ? "scrolled" : ""}`} id="top">
        {
          history.history.length > 1 && 
          <SiteLink className="topNav__back" title="Go Back" to={history.getPreviousPath()} >
            <FaAngleLeft /> BACK
          </SiteLink>
        } 
        {/* <div className="topNav__skewBar"></div> */} 
        <SiteLink to="/" className="topNav__logo">
          {/* <img src="/assets/logos/logo.png" alt="logo" /> */}
          <img src= { scrolled ? "/assets/logos/logo2.png" : "/assets/logos/logo.png"} alt="logo" />
        </SiteLink >
        <button id="mobile-menu-icon" type="button" aria-label="Menu" className={`hamburger hamburger--collapse ${menuOpened ? 'is-active' : ''}`} onClick={toggleMenu}>
          <span className="hamburger-box">
            <span className="hamburger-inner">
            </span>
          </span>
        </button>
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu} currentLocation={currentLocation}></NavBar>
      </header>
    </>
  );
}